import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../assets/descaling.sass"
import Image from "../../components/image"
import Carousel from 'react-bootstrap/Carousel'

const Electrical = () => (
  <div className="descaling-component">
    
    <SEO title="Electrical" />

    <div className="service-header">
      <h1>Electrical</h1>
    </div>

    <div className="descaling-statements descaling-statements-red">
      <p>
      Welcome to our premier electrical services,  we specialize in a wide range of electrical solutions tailored to meet your every need. Our expert team is dedicated to providing top-notch service in home interior lighting and electrical wiring, ensuring your living spaces are both functional and aesthetically pleasing. For entertainment enthusiasts, we offer professional TV installations and home theater setups that transform your viewing experience. Our expertise also extends to the outdoors, where we design and install both seasonal and permanent exterior lighting, enhancing the beauty and safety of your property.</p>
    </div>

    <div className="desktop-image-video">

      <Carousel>
          <Carousel.Item interval={2500}>
            <div className="scoping-images">
              <Image filename="lighting.png"/>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <div className="scoping-images">
              <Image filename="lightingtwo.png"/>            
            </div>
          </Carousel.Item>
      </Carousel>

    </div>
    
    <div className="descaling-statements desktop-statements">    
      <ul className="sewer-services-list">
        <li>Home interior lighting and electrical wiring</li>
        <li>TV installations and home theaters</li>
        <li>Exterior seasonal and permanent lighting</li>
        <li>Landscape lighting</li>
        <li>Appliance repairs</li>
      </ul>
    </div>

  </div>
)

export default Electrical
